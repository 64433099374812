<template>
  <div>
    <InputNumber
      :extraClass="isDirectMode ? 'w56' : 'w116'"
      :value.sync="countSync"
      :maxLength="20"
      @focusout="onFocusout"
    />
    <Input
      v-if="isDirectMode"
      class="w56"
      :value.sync="valueSync"
      :maxLength="4"
      @focusout="onFocusout"
    />
    <comm-opt
      class="w87"
      :optionDesc="'수량 단위 선택상자'"
      :optionName="selectedValue"
      :optionList="amountList"
      :eventName="'select'"
      :allFlag="false"
      @select="onChangeSelect"
    />
  </div>
</template>

<script>
import CommOpt from "@/_approval/components/common/CommOpt";
import InputNumber from "@/_approval/components/common/input/InputNumber";
import Input from "@/_approval/components/common/input/Input";

export default {
  components: {
    CommOpt,
    InputNumber,
    Input,
  },
  props: {
    value: String,
    count: String,
  },
  data() {
    return {
      selectedValue: "EA",

      amountList: [
        { code: "EA", name: "EA" },
        { code: "식", name: "식" },
        { code: "BOX", name: "Box" },
        { code: "직접입력", name: "직접입력" },
      ],
    };
  },
  computed: {
    isDirectMode() {
      return this.selectedValue === "직접입력";
    },
    countSync: {
      get() {
        return this.count;
      },
      set(value) {
        this.$emit("update:count", value);
      },
    },
    valueSync: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  watch: {
    value(newVal, oldVal) {
      this.setValueByProps();
    },
  },
  beforeMount() {
    // this.$store.dispatch(GET_QUANTITY_CODE_ACTION);
    this.setValueByProps();
  },
  methods: {
    onChangeSelect(obj) {
      if (obj.code === "직접입력") {
        this.$emit("update:value", "");
      } else {
        // this.$emit("update:value", value);
        this.$emit("update:value", obj.code);
      }
    },
    setValueByProps() {
      var toSelectedValue;
      switch (this.value) {
        case "EA":
        case "BOX":
        case "식":
          toSelectedValue = this.value;
          break;
        default:
          // 직접입력을 바뀔 때 값 초기화.
          // this.value = '';

          toSelectedValue = "직접입력";
      }

      if (this.selectedValue !== toSelectedValue) {
        this.selectedValue = toSelectedValue;
      }
    },
    onFocusout(e) {
      // e.target.classList.remove('error');
      this.$emit("onFocusout", e);
    },
  },
};
</script>

<style></style>
